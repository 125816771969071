<script>
  import { link } from "svelte-routing";
  import { imageSizeFolder } from "../helpers/imagesHelper";

  export let house;
  export let onUrlChange;

  const imageUrl = `${house.imageUrl}/${imageSizeFolder()}/${
    house.topImageName
  }`;
</script>

<a
  class="card"
  href={`himlalyckan-${house.id}`}
  use:link
  on:click={onUrlChange}
>
  <div class="card__image-container">
    <img class="card__image" src={imageUrl} alt="House" />
  </div>

  <div class="card__info">
    <h2 class="card__heading">{house.name}</h2>

    <p class="card__paragraph">
      <span class="demi-bold">Antal bäddar: </span>
      {house.numberOfBeds}
    </p>

    <p class="card__paragraph">
      <span class="demi-bold">Pris per vecka: </span>
      {house.price}
    </p>

    <div class="card__read-more-section">
      <div class="card__read-more">
        <span class="card__read-more-text">Läs mer</span>
        <span class="card__read-more-icon-container">
          <svg
            class="card__read-more-icon"
            aria-hidden="true"
            focusable="false"
            role="img"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
            /></svg
          >
        </span>
      </div>
    </div>
  </div>
</a>

<style type="text/scss">
  @import "./../styles/Card.scss";
</style>
