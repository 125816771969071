<script>
  export let house;
</script>

<section class="description-section">
  <div class="description-section__content">
    <p class="description-section__text">{house.description}</p>

    <article class="info-list">
      <div class="info-list-item">
        <span class="info-list-item__type">Antal bäddar: </span>
        <span class="info-list-item__value">{house.numberOfBeds}</span>
      </div>

      <div class="info-list-item">
        <span class="info-list-item__type">Pris per vecka: </span>
        <span class="info-list-item__value">{house.price}</span>
      </div>

      <div class="info-list-item">
        <span class="info-list-item__type">Storlek: </span>
        <span class="info-list-item__value">{house.size}</span>
      </div>

      <div class="info-list-item">
        <span class="info-list-item__type">Faciliteter: </span>
        <span class="info-list-item__value">{house.facilities}</span>
      </div>

      <div class="info-list-item">
        <span class="info-list-item__type">Köksutrustning: </span>
        <span class="info-list-item__value">{house.kitchenFacilities}</span>
      </div>

      <div class="info-list-item">
        <span class="info-list-item__type">Övrigt: </span>
        <span class="info-list-item__value">{house.otherInfo}</span>
      </div>
    </article>
  </div>
</section>

<style type="text/scss">
  @import "./DescriptionSection.scss";
</style>
