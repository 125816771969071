import App from './App.svelte';

window.addEventListener('DOMContentLoaded', (event) => {
	new App({
		target: document.body
	});

	// Scroll back up to top when navigation between pages
	history.pushState = new Proxy(history.pushState, {
		apply (target, thisArg, argumentsList) {
			scrollTo(0,0)
			Reflect.apply(target, thisArg, argumentsList)
		}
	})
});