<script>
  import { Router, Route } from "svelte-routing";
  import houses from "./texts/houses.js";
  import { onNavigationWithHash } from "./helpers/navigationHelper";

  import Header from "./components/Header.svelte";
  import Footer from "./components/Footer.svelte";
  import StartPage from "./pages/StartPage/StartPage.svelte";
  import HousePage from "./pages/HousePage/HousePage.svelte";
  import ContactPage from "./pages/ContactPage/ContactPage.svelte";
  import TipsPage from "./pages/TipsPage/TipsPage.svelte";

  export let url = "";
  let pathname = window.location.pathname;

  $: if (window.location.hash) {
    onNavigationWithHash(window.location.hash);
  }

  const onUrlChange = () => {
    setTimeout(() => {
      pathname = window.location.pathname;
    }, 100);
  };
</script>

<Router {url}>
  <Header {pathname} {onUrlChange} />
  <main>
    <Route path="himlalyckan-1">
      <HousePage id={1} {houses} />
    </Route>

    <Route path="himlalyckan-2">
      <HousePage id={2} {houses} />
    </Route>

    <Route path="himlalyckan-3">
      <HousePage id={3} {houses} />
    </Route>

    <Route path="himlalyckan-4">
      <HousePage id={4} {houses} />
    </Route>

    <Route path="naromradet">
      <TipsPage />
    </Route>

    <Route path="kontakt">
      <ContactPage />
    </Route>

    <Route path="/">
      <StartPage {onUrlChange} />
    </Route>
  </main>

  {#if pathname !== "/kontakt"}
    <Footer />
  {/if}
</Router>

<style type="text/scss">
  @import "./styles/global/App.scss";
</style>
