export default [
    {
        "id": 1,
        "name": "Himlalyckan 1",
        "description": "Vid havet i underbara Grankullavik ligger denna nyrenoverade stuga med fyra bäddar. Tre sovrum, varav två med dubbelsäng och ett med våningssäng. Allrum med TV, fullt utrustat kök och nytt badrum med WC och dusch. Köket har spis med fyra plattor och ugn samt mikrougn. Egen trädgård med stor altan och grill samt gräsmatta, hund- och småbarnssäkert staket. Endast ca 100 m till havet med badplats och brygga, nära till den välkända Bödabukten med dess milslånga sandstränder. Även nära till natursköna Naturum Trollskogen med alla dess aktiviteter samt till Byxelkrok och Böda med allt som finns där.",
        "price": "3900-7500 kr",
        "numberOfBeds": "6",
        "imageUrl": "/assets/images/1",
        "topImageName": "2.1.jpg",
        "size": "57 kvm",
        "facilities": "Toalett, dusch",
        "kitchenFacilities": "Frys, kylskåp, spis, mikrovågsugn, vattenkokare, kaffekokare",
        "otherInfo": "Grillmöjligheter, altan med utemöbler, hundar tillåtna",
        "numberOfImages": 11
    },
    {
        "id": 2,
        "name": "Himlalyckan 2",
        "description": "Vid havet i underbara Grankullavik på norra Öland ligger denna stuga med fem bäddar. Tre sovrum, varav ett med dubbelsäng, ett med en våningssäng och ett med en enkelsäng. Allrum kombinerat med kök. TV, micro, spis med fyra plattor och riktig varmluftsugn. WC och dusch. Egen trädgård med stor altan, grill och gräsmatta, hund- och småbarnssäkert staket. Ca 100 m från havet med badplats och brygga, nära till Bödabukten med milslånga sandstränder. Nära till natursköna Trollskogen med alla dess aktiviteter samt till Byxelkrok och Böda med allt som finns där.",
        "price": "4200-7900 kr",
        "numberOfBeds": "5-6",
        "imageUrl": "/assets/images/2",
        "topImageName": "1.jpg",
        "size": "48kvm",
        "facilities": "Toalett, dusch",
        "kitchenFacilities": "Frys, kylskåp, spis, mikrovågsugn, vattenkokare, kaffekokare",
        "otherInfo": "Grillmöjligheter, altan med utemöbler, hundar tillåtna",
        "numberOfImages": 10
    },
    {
        "id": 3,
        "name": "Himlalyckan 3",
        "description": "Fräscht och mysigt boende endast ca 100 m från havet i underbara Grankullavik. Tre stugor intill varandra med kök, vardagsrum samt toa/dusch i ett av husen, tre sovrum samt toa/dusch i det andra huset och två sovrum i det tredje. Totalt alltså fem sovrum, varav tre med dubbelsängar och två med våningssängar. Fullt utrustat kök med spishäll med ugn och fyra plattor, diskmaskin, mikro, stor kyl och frys. I det ena badrummet finns också tvättmaskin. Stor altan för alla väderstreck, grill och egen trädgård med hund- och småbarnssäkert staket, inga grannar direkt inpå. Lugnt, men ändå nära till allt. Badplats vid havet, dessutom mycket nära till Bödabukten med dess milslånga sandstränder. Mataffär och restauranger i närliggande Byxelkrok, även ett pensionat vid havet 300 m från stugan med café, restaurang, pizzeria och livemusik. Stugan ligger naturskönt och nära avfartsvägen mot Trollskogen.",
        "price": "5300-11700 kr",
        "numberOfBeds": "10",
        "imageUrl": "/assets/images/3",
        "topImageName": "1.jpg",
        "size": "85 kvm",
        "facilities": "Toalett, dusch, tvättmaskin",
        "kitchenFacilities": "Diskmaskin, kylskåp, frys, spis, mikrovågsugn, vattenkokare, kaffekokare",
        "otherInfo": "Grillmöjligheter, altan med utemöbler, hundar tillåtna",
        "numberOfImages": 19
    },
    {
        "id": 4,
        "name": "Himlalyckan 4",
        "description": "Vid havet i underbara Grankullavik längst upp på norra Öland ligger detta nyrenoverade och rymliga hus med åtta bäddar. Fyra sovrum, varav två med dubbelsäng (det ena kan enkelt ändras till två enkelsängar) och de andra två med vardera två enkelsängar. Stort allrum, mycket välutrustat kök med diskmaskin och två badrum med WC, handfat och dusch, även tvättmaskin. Egen inhägnad trädgård (småbarns- och hundsäkera staket), mycket stor altan och egen grillplats. Ca 100 m till havet med badplatser och brygga, nära till Bödabukten med milslånga sandstränder. Mycket naturskönt, nära till Trollskogen med alla dess aktiviteter och speciella natur samt till Böda och Byxelkrok med dess underbara hamn och allt som finns där.",
        "price": "5300-11 900 kr",
        "numberOfBeds": "8",
        "imageUrl": "/assets/images/4",
        "topImageName": "3.jpg",
        "size": "90 kvm",
        "facilities": "Toalett, dusch, tvättmaskin",
        "kitchenFacilities": "Diskmaskin, kylskåp, frys, spis, mikrovågsugn, vattenkokare, kaffekokare",
        "otherInfo": "Grillmöjligheter, altan med utemöbler, hundar tillåtna",
        "numberOfImages": 17
    }
];