<script>
  import { onMount } from "svelte";
  import { imageSizeFolder } from "../../../helpers/imagesHelper";

  export let house;

  const imagesUrl = `/assets/images/${house.id}/${imageSizeFolder()}`;
  let images = [];
  let selectedImageIndex = null;

  onMount(() => {
    const newImages = [];

    for (let i = 1; i <= house.numberOfImages; i++) {
      newImages.push(`${imagesUrl}/${i}.jpg`);
    }

    images = newImages;
  });

  const handleImageClick = (clickedImageIndex) => {
    selectedImageIndex = clickedImageIndex;
  };

  const handleCloseModal = (e) => {
    if (
      !e.target.classList.contains("images-modal__button") &&
      !e.target.classList.contains("images-modal__image") &&
      !e.target.classList.contains("images-modal__button-icon") &&
      !e.target.classList.contains("images-modal__button-icon-path")
    ) {
      selectedImageIndex = null;
    }
  };

  const handleNextClick = () => {
    if (selectedImageIndex === images.length - 1) {
      selectedImageIndex = 0;
      return;
    }

    selectedImageIndex++;
  };

  const handlePreviousClick = () => {
    if (selectedImageIndex === 0) {
      selectedImageIndex = images.length - 1;
      return;
    }

    selectedImageIndex--;
  };

  document.addEventListener("keydown", (e) => {
    if (selectedImageIndex !== null) {
      if (e.key === "ArrowLeft") {
        handlePreviousClick();
      } else if (e.key === "ArrowRight") {
        handleNextClick();
      }
    }
  });
</script>

<section class="images-section">
  <div class="images-section__content">
    {#each images as imageUrl, i}
      <img
        class="images-section__image"
        src={imageUrl}
        alt="house"
        on:click={() => handleImageClick(i)}
      />
    {/each}
  </div>

  {#if selectedImageIndex !== null}
    <div class="images-modal-wrapper">
      <div class="images-modal-overlay" on:click={handleCloseModal} />
      <article class="images-modal" on:click={handleCloseModal}>
        <button
          class="images-modal__button images-modal__button--prev"
          on:click={handlePreviousClick}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            class="images-modal__button-icon"
            ><path
              class="images-modal__button-icon-path"
              fill="currentColor"
              d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
            /></svg
          >
        </button>

        <img class="images-modal__image" src={images[selectedImageIndex]} />

        <button
          class="images-modal__button images-modal__button--next"
          on:click={handleNextClick}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            viewBox="0 0 448 512"
            class="images-modal__button-icon"
            ><path
              class="images-modal__button-icon-path"
              fill="currentColor"
              d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
            />
          </svg>
        </button>
      </article>
    </div>
  {/if}
</section>

<style type="text/scss">
  @import "./ImagesSection.scss";
</style>
