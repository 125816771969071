<script>
  import { imageSizeFolder } from "../../../helpers/imagesHelper";
  export let house;

  const imageUrl = `${house.imageUrl}/${imageSizeFolder()}/${
    house.topImageName
  }`;
</script>

<section class="top-image-wrapper">
  <div class="top-image-wrapper__inner">
    <img src={imageUrl} class="top-image" alt="bild på hus" />
    <h1 class="house-title">{house.name}</h1>
  </div>
</section>

<style type="text/scss">
  @import "./TopImage.scss";
</style>
