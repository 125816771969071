<script>
  import houses from '../../texts/houses.js';

  import IntroText from './Components/IntroText.svelte';
  import CardContainer from '../../components/CardContainer.svelte';

  export let onUrlChange;
</script>

<IntroText />
<CardContainer {houses} {onUrlChange} />
