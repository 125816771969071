<script>
  import Card from "./Card.svelte";
  import houses from "./../texts/houses.js";

  export let onUrlChange;
</script>

<section class="card-container">
  <div class="card-container__content">
    {#each houses as house}
      <Card {house} {onUrlChange} />
    {/each}
  </div>
</section>

<style type="text/scss">
  @import "./../styles/Card.scss";
</style>
